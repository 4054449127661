document.addEventListener('DOMContentLoaded', function () {
  let cDataTable = new DataTable('#datatable-buttons.pages-report',{
    "stateSave": false,
    "processing": true,
    "serverSide": true,
    "bPaginate": false,
    "ajax": { url: $('#datatable-buttons').data('source'),
    beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
    data: function (d) {
      return $.extend( {}, d, {
        "category_type": $('.category-type.active').val() 
      }); }
    },
    "responsive": false,
    "order": [1, 'desc'],
    "dom": '<"top">' + '<"clearfix">' + '<"table-responsive"rt>' + '<"clearfix"> ' + '<"clear">',
    "columns": [
      {data: 'type'},
      {data: 'updated_at', class: 'text-end'},
      {data: 'view', class: 'text-end padding-right-33px'},
    ],
    "columnDefs": [
      {
        "targets": [0, 2],
        "orderable": false
      }
    ],
    "language": {
    }
  });
  $("#search-input #search_datatable").keyup(function(event){
    if( event.keyCode != 13 )
      cDataTable.search($(this).val()).draw();
  });
  $(".select-filter-pages").click(function(event) {
    cDataTable.draw();
  })
});