document.addEventListener('DOMContentLoaded', function () {
  let cDataTable = new DataTable('#datatable-logs',{
    "stateSave": true,
    "processing": true,
    "serverSide": true,
    "stateSaveCallback": function (settings, data) {
      $.ajax({
        url: $('#datatable-buttons').data('source'),
        beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
        data: function (d) {
          return $.extend( {}, d, {
            "filter[admin_emails]": getAdminEmail(),
            "filter[types]": getType(),
            "filter[date]": getDate(),
          })
        }
      })
    },
    "stateLoadCallback": function (settings, callback) {
      $.ajax({
        url: $('#datatable-buttons').data('source'),
        beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
        data: function (d) {
          return $.extend( {}, d, {
            "filter[admin_emails]": getAdminEmail(),
            "filter[types]": getType(),
            "filter[date]": getDate(),
          })
        },
        success: function () {
          callback();
        }
      })
    },
    "ajax": {
      url: $('#datatable-buttons').data('source'),
      beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
      data: function (d) {
        return $.extend( {}, d, {
          "filter[admin_emails]": getAdminEmail(),
          "filter[types]": getType(),
          "filter[date]": getDate(),
        })
      }
    },
    "pagingType": "simple_numbers",
    "responsive": false,
    "lengthMenu": [ [10, 20, 50, 100, -1], ['10 per page', '20 per page', '50 per page', '100 per page', 'All'] ],
    "oLanguage": {
      "sLengthMenu": "_MENU_"
    },
    "order": [0, 'desc'],
    "dom": '<"top">' + '<"clearfix">' + '<"table-responsive"rt>' + '<"clearfix"> ' + '<"bottom"<"d-flex justify-content-between"i<"d-flex align-items-center"<"margin-right-20px"l>p>>> ' + '<"clear">',
    "columnDefs": [],
    "columns": [
      {data: "id"},
      {data: "admin"},
      {data: "activity"},
      {data: "details"},
      {data: "created_at"},
    ]
  });

  function getAdminEmail(){
    let admin_emails = $('#admin input:checkbox:checked').map(function(){
      return $(this).val();
    }).get();
    return admin_emails
  }

  function getType(){
    let types = $('#type input:checkbox:checked').map(function(){
      return $(this).val();
    }).get();
    return types
  }

  function getDate(){
    let dates = $('.filter-date input[type=hidden]').map(function(){
      if ($(this).val() != "") {
        return $(this).val()
      }
    }).get();
    return dates
  }

  $('#filter-submit').click(function(){
    cDataTable.draw()
    $('.filter-list').removeClass('show')
    $('.filter-btn').removeClass('show')
    $('.dropdown-menu').removeClass('show')
  })

  $('#remove-filter').click(function(){
    $('#admin input:checkbox:checked').prop('checked', false)
    $('#type input:checkbox:checked').prop('checked', false)
    $('.flatpickr-input').val('')
    $('.filter-list').removeClass('show')
    $('.filter-btn').removeClass('show')
    $('.dropdown-menu').removeClass('show')
    cDataTable.draw()
  })

  $('.filter-date input[name=startDate]').change(function(){
    let startDate = $(this).val()
    let endDate = $('.filter-date input[name=endDate]').val()
    if (startDate > endDate) {
      $('.invalid-date').empty().text('The end date must be a valid date and later than the start date.')
      $('#filter-submit').attr('disabled', 'disabled')
    } else {
      $('.invalid-date').empty()
      $('#filter-submit').removeAttr('disabled')
    }
  })
  $('.filter-date input[name=endDate]').change(function(){
    let endDate = $(this).val()
    let startDate = $('.filter-date input[name=startDate]').val()
    if (startDate > endDate) {
      $('.invalid-date').empty().text('The end date must be a valid date and later than the start date.')
      $('#filter-submit').attr('disabled', 'disabled')
    } else {
      $('.invalid-date').empty()
      $('#filter-submit').removeAttr('disabled')
    }
  })
  $("#search-input #search_datatable").keyup(function(event){
    if( event.keyCode != 13 )
      cDataTable.search($(this).val()).draw();
  });
});
