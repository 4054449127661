document.addEventListener('DOMContentLoaded', function () {
  let cDataTable = new DataTable('#datatable-buttons.cms-application-users',{
    "stateSave": true,
    "processing": true,
    "serverSide": true,
    "stateSaveCallback": function (settings, data) {
      $.ajax( {
        url: $('#datatable-buttons').data('source'),
        })
    },
    "stateLoadCallback": function (settings, callback) {
      $.ajax( {
        url: $('#datatable-buttons').data('source'),
        success: function () {
          callback();
        }
      })
    },
    "ajax": { url: $('#datatable-buttons').data('source') },
    "pagingType": "simple_numbers",
    "responsive": false,
    "lengthMenu": [ [10, 20, 50, 100, -1], ['10 per page', '20 per page', '50 per page', '100 per page', 'All'] ],
    "order": [7, 'desc'],
    "dom": '<"top">' + '<"clearfix">' + '<"table-responsive"rt>' + '<"clearfix"> ' + '<"bottom"<"d-flex justify-content-between"i<"d-flex align-items-center"<"margin-right-20px"l>p>>> ' + '<"clear">',
    "columns": [
      {data: 'ids',
      render : function(data) {
        return '<div class="text-center padding-top-7px"><input class="form-check-input" type="checkbox" value="'+ data +'" name="checkbox[]" onchange="disable_button(this)"></div>'
        }    
      },
      {data: 'id'},
      {data: 'first_name'},
      {data: 'last_name'},
      {data: 'email'},
      {data: 'phone_number'},
      {data: 'description'},
      {data: 'action'},
      {data: 'created_at'}
    ],
    "oLanguage": {
      "sSearch": "Search: ",
      "lengthMenu": "_MENU_ &nbsp;",
      "loadingRecords": '&nbsp;',
      "processing": '<div class="loader">Loading...</div>'
    },
    "columnDefs": [
      {
        "targets": [0,5,6,7],
        "orderable": false,
      }
    ],
    "language": {}
  });
  $("#search-input #search_datatable").keyup(function(event){
    if( event.keyCode != 13 )
      cDataTable.search($(this).val()).draw();
  });

  window.updateStatus = function(status, user_id) {
    $.ajax({
      url: `/admin/users/${user_id}/application_status`,
      type: 'PUT',
      dataType: "json",
      data: {
        status: status,
      },
      beforeSend: function(e) {
        $('.modal').modal('hide')
        $('#modal_loading').modal('show')
      },
      success: function(data) {
        cDataTable.draw();
        $('.modal').modal('hide')
        load_flash_messages("success", data["message"])
        if (data["notifications"] > 0)
          $('.notification-icon').html(data["notifications"])
        else
          $('.notification-icon').remove()
      }
    })
  }

  window.confirmUpdateStatus = function(status, user_id) {
    var content;
    if (status == "active") {
      content = "Do you want to approve the user?"
    } else {
      content = "Do you want to reject the user?"
    }
    $('#modal_update_status #content').html(content)
    $('#modal_update_status .btn-confirm').attr("onclick", `updateStatus("${status}", ${user_id})`)
    $('#modal_update_status').modal('show')
  }

  $("#delete_all").on('click', function () {
    var ids = $(".form-check-input:checked[type=checkbox]")
    .map(function(){return $(this).val();}).get();
    
    $.ajax({
      url: "/admin/users/delete_all",
      method: "delete",
      dataType: "JSON",
      data: 
      {
        ids: ids
      },
      success: function () {
        location.reload()
      }
    });
  })

});
