import Flatpickr from "stimulus-flatpickr";
import Rails from "rails-ujs";

// import a theme (could be in your main CSS entry too...)
// import "flatpickr/dist/themes/dark.css";

// import the translation files and create a translation mapping
import { English } from "flatpickr/dist/l10n/default.js";

// create a new Stimulus controller by extending stimulus-flatpickr wrapper controller
export default class extends Flatpickr {
  locales = {
    en: English
  };

  initialize() {
    // sets your language (you can also set some global setting for all time pickers)
    this.config = {
      locale: English
    }
  }

  connect() {
    //define locale and global flatpickr settings for all datepickers
    this.config = {
      locale: English,
      altInput: true,
      showMonths: 1
    };

    super.connect();
  }

  // automatically submit form when a date is selected
  // change(selectedDates, dateStr, instance) {
  //   const form = this.element.closest("form");
  //   Rails.fire(form, "submit");
  // }

  get locale() {
    if (this.locales && this.data.has("locale")) {
      return this.locales[this.data.get("locale")];
    } else {
      return "";
    }
  }
}
