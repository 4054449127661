import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

import "./src/jquery"
import "./src/datatables-bs5"

import cocoon from '@nathanvda/cocoon'
require("@nathanvda/cocoon")

import select2 from 'select2'

window.select2 = select2()



import "./src/admin/applicant"
import "./src/pages/application"

import Modal from "bootstrap/js/dist/modal"
import Tooltip from "bootstrap/js/dist/tooltip"
import Popover from "bootstrap/js/dist/popover"

import "./src/admin/datatables/articles"
import "./src/admin/datatables/reports"
import "./src/admin/datatables/users"
import "./src/admin/datatables/application_users"
import "./src/admin/datatables/activity_logs"
import "./src/admin/datatables/dividend_statements"

import "./src/pages/datatables/articles"
import "./src/pages/datatables/reports"
import "./src/pages/datatables/dividend_statements"
import "./src/pages/input"

$.ajaxSetup({
  headers: {
    'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
  }
});
