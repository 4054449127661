document.addEventListener('DOMContentLoaded', function () {
  let cDataTable = new DataTable('#list_reports-datatables',{
    "stateSave": true,
    "processing": true,
    "serverSide": true,
    "stateSaveCallback": function (settings, data) {
      $.ajax( {
        url: $('#list_reports-datatables').data('source')
      })
    },
    "stateLoadCallback": function (settings, callback) {
      $.ajax({
        url: $('#list_reports-datatables').data('source'),
        success: function () {
          callback();
        }
      });
    },
    "ajax": { url: $('#list_reports-datatables').data('source') },
    "pagingType": "simple_numbers",
    "responsive": false,
    "lengthMenu": [ [-1], ['All'] ],
    "order": [2, 'desc'],
    "dom": '<"top">' + '<"clearfix">' + '<"table-responsive"rt>' + '<"clearfix"> ' + '<"bottom"<"d-flex justify-content-between"<"d-flex align-items-center"<"margin-right-20px">>>> ' + '<"clear">',
    "columnDefs": [
      {
        "targets": 5,
        "orderable": false,
      }
    ],
    "columns": [
      {data: "id"},
      {data: "type"},
      {data: "date"},
      {data: "file"},
      {data: "updated_at"},
      {data: "action"}
    ],
    "oLanguage": {
      "sSearch": "Search: ",
      "lengthMenu": "_MENU_ &nbsp;",
      "loadingRecords": '&nbsp;',
      "processing": '<div class="loader">Loading...</div>'
    },
  });


  $("#search-input #search_datatable").keyup(function(event){
    if( event.keyCode != 13 )
      cDataTable.search($(this).val()).draw();
  });
  $(".select-filter-cms").change(function(event) {
    cDataTable.draw();
  })
});
