window.addEventListener('DOMContentLoaded', () => {
  $('.btn-signup').prop('disabled', true);
  document.querySelectorAll('a').forEach(function (el) {
    el.dataset.turbo = false
  })
  window.typingInput = (e) => {
    e.parents(".input-password").next(".invalid-feedback").removeClass("d-block")
  }

  window.turnOffMobileMenu = () => {
    $(".mobile-menu-landing").addClass("d-none")
    $(".container-fluid").css("overflow-y", "unset")
  }
  window.turnOnMobileMenu = () => {
    $(".mobile-menu-landing").removeClass("d-none")
    $(".container-fluid").css("overflow-y", "hidden")
  }
})

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(function() {
    $('#flash_notice').remove();
    $('#flash_error').remove();
    $('#alert_dismissible').remove();
  }, 3000);
})
