window.addEventListener('DOMContentLoaded', () => {
  window.handle_input_pw = that => {
    let input_pw = $(that).parent().find('input')
    if ($(that).hasClass('fa-eye-slash')) {
      $(that).removeClass('fa-eye-slash').addClass('fa-eye')
      input_pw.attr("type", "text")
    } else {
      $(that).removeClass('fa-eye').addClass('fa-eye-slash')
      input_pw.attr("type", "password")
    }
  }

  window.shortenLeftSideBar = () => {
    let leftBar = $("#left-sidebar-cms")
    if ( leftBar.hasClass("normal-form") ) {
      leftBar.removeClass("normal-form").addClass("shorten-form")
      $(".cms-navbar-title, .cms-nav-items").addClass("d-none")
      $(".shorten-cms-nav-items").removeClass("d-none")
      sessionStorage.setItem("cms-sidebar", "hide")
    } else {
      leftBar.removeClass("shorten-form").addClass("normal-form")
      $(".cms-navbar-title, .cms-nav-items").removeClass("d-none")
      $(".shorten-cms-nav-items").addClass("d-none")
      sessionStorage.setItem("cms-sidebar", "show")
    }
  }

  window.load_flash_messages = (status, msg) => {
    $('.flash-messages').html(
    `<div class="alert alert-${status}">
      <ul class="margin-0px">
        <div class="text-center">
          <p class="margin-0px">${msg}</p>
        </div>
      </ul>
    </div>`)
    setInterval(function () {
      $('.alert').fadeOut()
    }, 5000);
  }

  if ( sessionStorage.getItem("cms-sidebar") == "hide" ) {
    $(".navbar-toggler").trigger("click")
  } else {
    // Nothing
  }
})
$(document).on("turbo:load", () => {
  window.readURL_image = input => {
    if (input.files && input.files[0]) {
      if($(input).get(0).files.length > 0){
        var error_field;
        error_field = $(input).parents('.nested-fields').find('.invalid-feedback')
        if (error_field.length == 0)
          error_field = $(input).parents('.form-group').find('.invalid-feedback')
        var fileSize = $(input).get(0).files[0].size;
        if (fileSize < 5 * 1024 * 1024 ){
          var reader = new FileReader();
          reader.onload = function (e) {
            $(input).parent().find('img').attr("src",e.target.result)
            $(input).parent().find('.input_add_image').removeClass('d-none')
            $(input).parent().find('input[type="hidden"]').remove()
          };
          reader.readAsDataURL(input.files[0]);
          error_field.html('').removeClass('d-block')
        }
        else {
          error_field.html('Files larger than 5MB.').addClass('d-block')
          console.log('Files larger than 5mb');
        }
      }
    }
  }

  window.change_role = that => {
    role = $(that).val()
    if (role == 'captain' || role == 'manager')
      $('#form_ma_id').removeClass('d-none')
    else
      $('#form_ma_id').addClass('d-none')
  }
})
