document.addEventListener('DOMContentLoaded', function () {
  let cDataTable = new DataTable('#datatable-buttons.cms-users',{
    "stateSave": true,
    "processing": true,
    "serverSide": true,
    "stateSaveCallback": function (settings, data) {
      $.ajax( {
        "url": $('#datatable-buttons').data('source'),
        beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
        data: function (data) {
          return $.extend( {}, data, {
            "user_type": $('#user_status').val()
          });
        }
      })
    },
    "stateLoadCallback": function (settings, callback) {
      $.ajax({
        "url": $('#datatable-buttons').data('source'),
        beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
        data: function (data) {
          return $.extend( {}, data, {
            "user_type": $('#user_status').val()
          });
        },
        success: function () {
            callback();
        }
      });
    },
    "ajax": {
      "url": $('#datatable-buttons').data('source'),
      beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
      data: function (data) {
        return $.extend( {}, data, {
          "user_type": $('#user_status').val()
        });
      }
    },
    "pagingType": "simple_numbers",
    "responsive": false,
    "lengthMenu": [ [10, 20, 50, 100, -1], ['10 per page', '20 per page', '50 per page', '100 per page', 'All'] ],
    "order": [3, 'asc'],
    "dom": '<"top">' + '<"clearfix">' + '<"table-responsive"rt>' + '<"clearfix"> ' + '<"bottom"<"d-flex justify-content-between"i<"d-flex align-items-center"<"margin-right-20px"l>p>>> ' + '<"clear">',
    "columns": [
      {data: 'id'},
      {data: 'first_name'},
      {data: 'last_name'},
      {data: 'email'},
      {data: 'phone_number'},
      {data: 'user_type'},
      {data: 'status'},
      {data: 'action'}
    ],
    "oLanguage": {
      "sSearch": "Search: ",
      "lengthMenu": "_MENU_ &nbsp;",
      "loadingRecords": '&nbsp;',
      "processing": '<div class="loader">Loading...</div>'
    },
    "columnDefs": [
      {
        "targets": 7,
        "orderable": false,
      }
    ],
    "language": {}
  });
  $("#search-input #search_datatable").keyup(function(event){
    if( event.keyCode != 13 )
      cDataTable.search($(this).val()).draw();
  });
  $(".select-filter-cms").change(function(event) {
    cDataTable.draw();
  })
});