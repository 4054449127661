document.addEventListener('DOMContentLoaded', function () {
  let cDataTable = new DataTable('#datatable-buttons.cms-dividend-statement',{
    "stateSave": false,
    "processing": true,
    "serverSide": true,
    "stateSaveCallback": function (settings, data) {
      $.ajax( {
        url: $('#datatable-buttons').data('source'),
        beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
        data: function (d) {
          return $.extend( {}, d, {
            "filter[date]": getDate(),
          })
        }
      })
    },
    "stateLoadCallback": function (settings, callback) {
      $.ajax({
        url: $('#datatable-buttons').data('source'),
        beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
        data: function (d) {
          return $.extend( {}, d, {
            "filter[date]": getDate(),
          })
        },
        success: function () {
          callback();
        }
      });
    },
    "ajax": { 
      url: $('#datatable-buttons').data('source'),
      beforeSend: (xhr) => xhr.setRequestHeader('Content-Type', 'application/json'),
      data: function (d) {
        return $.extend( {}, d, {
          "filter[date]": getDate(),
        })
      }
     },
    "pagingType": "simple_numbers",
    "responsive": false,
    "lengthMenu": [ [-1], ['All'] ],
    "order": [1, 'asc'],
    "dom": '<"top">' + '<"clearfix">' + '<"table-responsive"rt>' + '<"clearfix"> ' + '<"bottom"<"d-flex justify-content-between"<"d-flex align-items-center"<"margin-right-20px">>>> ' + '<"clear">',
    "columns": [
      {data: "id"},
      {data: "username"},
      {data: "carbon_copy"},
      {data: "date"},
      {data: "file"},
      {data: "updated_at"},
      {data: "action"}
    ],
    "oLanguage": {
      "sSearch": "Search: ",
      "lengthMenu": "_MENU_ &nbsp;",
      "loadingRecords": '&nbsp;',
      "processing": '<div class="loader">Loading...</div>'
    },
    "columnDefs": [
      {
        "targets": [2,6],
        "orderable": false,
      }
    ],
  });

  function getDate(){
    let dates = $('.filter-date input[type=hidden]').map(function(){
      if ($(this).val() != "") {
        return $(this).val()
      }
    }).get();
    return dates
  }

  $("#search-input #search_datatable").keyup(function(event){
    if( event.keyCode != 13 )
      cDataTable.search($(this).val()).draw();
  });
  $(".select-filter-cms").change(function(event) {
    cDataTable.draw();
  })

  $('.filter-date input[name=startDate]').change(function(){
    let startDate = $(this).val()
  })
});
