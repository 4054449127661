window.addEventListener('DOMContentLoaded', () => {
  window.eyePassword = (e) => {
    let input, input_type
    input      = $(e).parents('.section-password').find('input')
    input_type = input.attr('type')
    
    if ( input_type == 'password' ) {
      input.attr('type', 'text')
    } else {
      input.attr('type', 'password')
    }
    toggleEye(e)
  }
  
  window.toggleEye = (e) => {
    if ( $(e).hasClass('close-eye') ) {
      $(e).toggleClass('d-none')
      $(e).next().toggleClass('d-none')
    } else {
      $(e).toggleClass('d-none')
      $(e).prev().toggleClass('d-none')
    }
  }
})

window.loginMainPage = () => {
  const REGEX_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  let inputEmail, inputPassword, errorFrom, errorInputEmail, errorInputPassword
  
  inputEmail    = $("#new_user").find("#user_email").val()
  inputPassword = $("#new_user").find("#user_password").val()

  errorFrom          = []
  errorInputEmail    = []
  errorInputPassword = []

  if ( inputEmail == "" ) {
    errorFrom.push("Email is not blank.")
    errorInputEmail.push("Email is not blank.")
  }

  if ( inputEmail != "" && !REGEX_EMAIL.test(inputEmail) ) {
    errorFrom.push("Email is invalid.")
    errorInputEmail.push("Email is invalid.")
  }

  if ( inputPassword == "" ) {
    errorFrom.push("Password is not blank.")
    errorInputPassword.push("Password is not blank.")
  }

  if ( errorInputEmail.length > 0 ) {
    $(".error-email").removeClass("d-none")
    $(".error-email").text(errorInputEmail.join(", "))
  } else {
    $(".error-email").addClass("d-none")
    $(".error-email").text("")
  }

  if ( errorInputPassword.length > 0 ) {
    $(".error-password").removeClass("d-none")
    $(".error-password").text(errorInputPassword.join(", "))
  } else {
    $(".error-password").addClass("d-none")
    $(".error-password").text("")
  }

  if ( errorFrom.length == 0 ) {
    $("#new_user").submit()
  }
}

window.subcribeAccountUser = () => {
  const REGEX_EMAIL = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  let inputEmail, inputFirstName, inputLastName, errorFrom, errorInputEmail, errorInputFirstName, errorInputLastName
    
  inputEmail     = $("#new_user").find("#user_email").val()
  inputFirstName = $("#new_user").find("#user_first_name").val()
  inputLastName  = $("#new_user").find("#user_last_name").val()

  errorFrom           = []
  errorInputEmail     = []
  errorInputFirstName = []
  errorInputLastName  = []

  if ( inputEmail == "" ) {
    errorFrom.push("Email is not blank.")
    errorInputEmail.push("Email is not blank.")
  }

  if ( inputEmail != "" && !REGEX_EMAIL.test(inputEmail) ) {
    errorFrom.push("Email is invalid.")
    errorInputEmail.push("Email is invalid.")
  }

  if ( inputFirstName == "" ) {
    errorFrom.push("First name is not blank.")
    errorInputFirstName.push("First name is not blank.")
  }

  if ( inputLastName == "" ) {
    errorFrom.push("Last name is not blank.")
    errorInputLastName.push("Last name is not blank.")
  }

  if ( errorInputEmail.length > 0 ) {
    $(".error-email").removeClass("d-none")
    $(".error-email").text(errorInputEmail.join(", "))
  } else {
    $(".error-email").addClass("d-none")
    $(".error-email").text("")
  }

  if ( errorInputPassword.length > 0 ) {
    $(".error-password").removeClass("d-none")
    $(".error-password").text(errorInputPassword.join(", "))
  } else {
    $(".error-password").addClass("d-none")
    $(".error-password").text("")
  }

  if ( errorFrom.length == 0 ) {
    $("#new_user").submit()
  }
}

window.setInputFilter = (textbox, inputFilter, errMsg) => {
  ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop", "focusout"].forEach(function(event) {
    textbox.addEventListener(event, function(e) {
      if (inputFilter(this.value)) {
        if (["keydown","mousedown","focusout"].indexOf(e.type) >= 0) {
          this.classList.remove("input-error")
          this.setCustomValidity("")
        }
        this.oldValue = this.value;
        this.oldSelectionStart = this.selectionStart;
        this.oldSelectionEnd = this.selectionEnd
      } else if (this.hasOwnProperty("oldValue")) {
        this.classList.add("input-error")
        this.setCustomValidity(errMsg)
        this.reportValidity()
        this.value = this.oldValue
        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd)
      } else {
        this.value = ""
      }
    })
  })
}
