document.addEventListener('DOMContentLoaded', function () {
  let cDataTable = new DataTable('#datatable-buttons.cms-article',{
    "stateSave": false,
    "processing": true,
    "serverSide": true,
    "stateSaveCallback": function (settings, data) {
      $.ajax( {
        url: $('#datatable-buttons').data('source'),
        })
    },
    "stateLoadCallback": function (settings, callback) {
      $.ajax( {
        url: $('#datatable-buttons').data('source'),
        success: function () {
          callback();
        }
      })
    },
    "ajax": { url: $('#datatable-buttons').data('source') },
    "pagingType": "simple_numbers",
    "responsive": false,
    "lengthMenu": [ [10, 20, 50, 100, -1], ['10 per page', '20 per page', '50 per page', '100 per page', 'All'] ],
    "order": [3, 'desc'],
    "dom": '<"top">' + '<"clearfix">' + '<"table-responsive"rt>' + '<"clearfix"> ' + '<"bottom"<"d-flex justify-content-between"i<"d-flex align-items-center"<"margin-right-20px"l>p>>> ' + '<"clear">',
    "columns": [
      {data: 'id'},
      {data: 'title'},
      {data: 'category_type'},
      {data: 'created_at'},
      {data: 'article_img'},
      {data: 'action'}
    ],
    "oLanguage": {
      "sSearch": "Search: ",
      "lengthMenu": "_MENU_ &nbsp;",
      "loadingRecords": '&nbsp;',
      "processing": '<div class="loader">Loading...</div>'
    },
    "columnDefs": [
      {
        "targets": [4, 5],
        "orderable": false,
      }
    ],
    "language": {}
  });
  $("#search-input #search_datatable").keyup(function(event){
    if( event.keyCode != 13 )
      cDataTable.search($(this).val()).draw();
  });
});